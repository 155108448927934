.row-clickable {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.ant-input[disabled], .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number-disabled {
    background-color: white;
}

.ant-drawer-title {
    cursor: default;
}
